<template>
  <div>
    <app-breadcrumb />
    <b-tabs pills>
      <b-tab
        v-if="permission.access.sqAdd"
        title="Akun SQ"
      >
        <add-account-sq />
      </b-tab>
      <b-tab title="Bireporting">
        <b-card no-body>
          <div class="m-2">
            <b-row class="mb-1">
              <b-col
                cols="12"
                xl="3"
              >
                <v-select
                  v-model="selectedBulan"
                  class="mb-1"
                  :options="bulanOptions"
                  :clearable="false"
                  placeholder="Pilih Bulan"
                />
              </b-col>
              <b-col
                cols="12"
                xl="3"
              >
                <v-select
                  v-model="selectedStatus"
                  class="mb-1"
                  :options="statusOptions"
                  :clearable="false"
                  placeholder="Pilih Status"
                />
              </b-col>
              <b-col
                cols="12"
                xl="6"
              >
                <div class="d-flex justify-content-end mb-1">
                  <b-button variant="primary">
                    <feather-icon
                      icon="DownloadIcon"
                      size="16"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="m-2">
            <h6>Iki Bireporting</h6>
          </div>
        </b-card>
      </b-tab>
      <b-tab title="Review">
        <b-card>
          <h1>Iki Gae Review</h1>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BRow, BCol, BButton,
} from 'bootstrap-vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import permission from '@/permission'
import AddAccountSq from './AddAccountSq.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    AppBreadcrumb,
    vSelect,
    AddAccountSq,
  },
  data() {
    return {
      permission,
      selectedBulan: '',
      selectedStatus: '',
      bulanOptions: [
        {
          label: 'Bulan April',
          value: 4,
        },
        {
          label: 'Bulan Mei',
          value: 5,
        },
        {
          label: 'Bulan Juni',
          value: 6,
        },
        {
          label: 'Bulan Juli',
          value: 7,
        },
      ],
      statusOptions: [
        {
          label: 'Selesai',
          value: 4,
        },
        {
          label: 'Batal',
          value: 9,
        },
        {
          label: 'Sedang Proses',
          value: 5,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.bottom-sheet {
  padding: 16px 16px 200px 16px
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
