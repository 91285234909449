<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row
          align-h="between"
          align-v="center"
        >
          <b-col
            cols="12"
            md="4"
          >
            <h4 class="mb-1 mb-md-0">
              List Akun SQ
            </h4>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              block
              variant="primary"
              @click="openAddSheet()"
            >
              Tambah Akun SQ Baru
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refSqAccountTable"
        :items="sqList"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Data masih belum tersedia"
        class="position-relative"
      >
        <template #cell(nama)="data">
          <h6 class="text-primary m-0">
            {{ data.item.data.nama }}
          </h6>
          <small class="text-secondary">{{ data.item.data.email }}</small>
        </template>
        <template #cell(layanan)="data">
          <b-badge
            v-for="item in data.item.data.layanan"
            :key="item.id"
            class="mr-50 mb-50"
            variant="primary"
          >
            {{ vertikalMitra(item) }}
          </b-badge>
        </template>
        <template #cell(aksi)>
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              Dropdown Item 1
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <vue-bottom-sheet
      ref="newSQAccount"
      class="p-1"
      :rounded="false"
    >
      <div class="bottom-sheet">
        <div class="d-flex justify-content-between align-items-center">
          <h4>Tambah Akun Baru</h4>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="closeAddSheet()"
          />
        </div>
        <hr>
        <validation-observer ref="tambahSQ">
          <validation-provider
            ref="nama"
            #default="{ errors }"
            rules="required"
            name="Nama"
          >
            <b-form-group label="Nama">
              <b-form-input
                v-model="userData.nama"
                placeholder="Nama SQ"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Nomor HP"
            label-for="nomorHP"
          >
            <validation-provider
              #default="{ errors }"
              name="Nomor HP"
              rules="required"
            >
              <b-input-group prepend="62">
                <b-form-input
                  id="nomorHP"
                  v-model="no_hp"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nomor HP"
                  type="number"
                />
              </b-input-group>
              <small class="text-secondary mb-2">Format : 628xxxxxxxxx</small>
              <br>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            ref="email"
            #default="{ errors }"
            rules="required|email"
            name="Email"
          >
            <b-form-group label="Email">
              <b-form-input
                v-model="userData.email"
                placeholder="Email SQ"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            ref="layanan"
            #default="{ errors }"
            rules="required"
            name="Layanan"
          >
            <b-form-group label="Layanan">
              <v-select
                v-model="selectedVertikal"
                :options="vertikalOptions"
                placeholder="Layanan SQ"
                :clearable="false"
                multiple
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="register-password"
                  v-model="userData.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  name="register-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
        <b-button
          block
          variant="primary"
          @click="submitDataSQ"
        >
          Tambahkan
        </b-button>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BTable, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { db, auth } from '@/firebase'
import permission from '@/permission'

export default {
  name: 'AddAccountSq',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    VueBottomSheet,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      permission,
      required,
      email,
      vertikalOptions: [],
      selectedVertikal: [],
      no_hp: '',
      userData: {
        access: {
          sq: {
            read: true,
            print: true,
            layanan: [],
          },
        },
        email: '',
        nama: '',
        role: 'sq',
        status: 1,
        uid: '',
        no_hp: '',
        layanan: [],
        waktu_daftar: +new Date(),
        password: '',
      },
      tableColumns: [
        { key: 'nama', label: 'Nama', sortable: true },
        { key: 'layanan', sortable: false },
        { key: 'aksi', label: '' },
      ],
      sqList: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.getVertikal()
    this.getAccountSq()
  },
  methods: {
    openAddSheet() {
      this.$refs.newSQAccount.open()
    },
    closeAddSheet() {
      this.$refs.newSQAccount.close()
    },
    submitDataSQ() {
      this.$refs.tambahSQ.validate().then(success => {
        if (success) {
          this.selectedVertikal.forEach(el => {
            this.userData.layanan.push(el.value)
            this.userData.access.sq.layanan.push(el.value)
          })

          const idAdd = db.collection('mecarehub').doc().id

          auth.createUserWithEmailAndPassword(this.userData.email, this.userData.password).then(userCallback => {
            this.userData.uid = userCallback.user.uid
            this.userData.no_hp = `62${this.no_hp}`
            delete this.userData.password

            db.collection('mecarehub').doc(idAdd).set(this.userData).then(() => {
              this.userData.layanan.forEach(el => {
                db.collection('layanan').doc(el).set({
                  sq: idAdd,
                }, { merge: true })
              })
              this.closeAddSheet()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil Submit',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.userData.layanan = []
              this.userData.access.sq.layanan = []
            })
          })
        }
      })
    },
    async getVertikal() {
      await db.collection('layanan').get().then(async snapshot => {
        snapshot.docs.forEach(async doc => {
          if (!doc.data().sq) {
            this.vertikalOptions.push({
              label: doc.data().nama_vertikal,
              value: doc.data().kode_vertikal,
            })
          }
        })
      })
    },

    getAccountSq() {
      db.collection('mecarehub').where('role', '==', 'sq').get().then(snap => {
        snap.docs.forEach(doc => {
          this.sqList.push({
            id: doc.id,
            data: doc.data(),
          })
        })
      })
    },

    vertikalMitra(vl) {
      const vertikalVariant = {
        vl01: 'Akupunktur',
        vl02: 'Auto',
        vl03: 'Barber',
        vl04: 'Bekam',
        vl05: 'Beauty',
        vl06: 'Cleaning',
        vl07: 'Massage',
        vl08: 'Cuci AC',
      }

      return vertikalVariant[vl]
    },
  },
}
</script>

<style lang="scss" scoped>
.bottom-sheet {
  padding: 16px 16px 200px 16px
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
